import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';
import history from 'airborne/browserHistory';

import classnames from 'classnames';
import {connect} from 'react-redux';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import OutsideClickHandler from 'midoffice/components/OutsideClickHandler';
import CarsDestinationSearch from 'airborne/homepage2/CarsDestinationSearch';
import {getDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import {discardPNR} from 'airborne/store/modules/homepage/actions';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import ShortcutWrapper from 'midoffice/newforms/components/ShortcutWrapper';

@connect((state)=> getDestination(state), dispatch => ({
    onClearAll: () => dispatch(discardPNR()),
}))
export default class DestinationView extends React.Component {
    static propTypes = {
        pickUp: PropTypes.object,
        dropOff: PropTypes.object,
        dates: PropTypes.object,
        differentDropOff: PropTypes.bool,
        onClearAll: PropTypes.func.isRequired,
    };

    state = {edit: false};

    componentDidMount() {
        if (!this.props.pickUp) {
            history.push('/ui/homepage/');
            this.props.onClearAll();
        }
    }

    openEditForm = ()=> this.setState({edit: true});
    closeEditForm = ()=> this.setState({edit: false});

    renderDestination(mode, dest, date) {
        const label = mode === 'pick-up' ? gettext('Pick-Up') : gettext('Drop-Off');
        const className = classnames('head-panel__col', {
            'pull-left': mode === 'pick-up',
            'pull-right': mode === 'drop-off',
        });

        date = moment(date);

        const day = date.date();
        const month = date.format('MMM');
        const dayOfWeek = date.format('dddd');
        const [time, period] = date.format(settings.TIME_FORMAT).split(' ');
        const detailsClassName = classnames('head-panel__details', {
            'head-panel__details--time-period': period
        });

        return (
            <div className={className}>
                <div className="head-panel__location">
                    <h3 className="head-panel__location__header">{label}</h3>
                    <div className="head-panel__location__name">
                        {dest?.label}
                    </div>
                </div>
                <div className={detailsClassName}>
                    <div className="head-panel__details__date">{day}</div>
                    <aside className="head-panel__details__aside">
                        <div className="head-panel__details__month">{month}</div>
                        <div className="head-panel__details__day">{dayOfWeek}</div>
                    </aside>
                    <div className="head-panel__details__time">{time}</div>
                    {period &&
                        <div className="head-panel__details__period">{period}</div>}
                </div>
            </div>
        );
    }

    render() {
        const {pickUp, dropOff, differentDropOff, dates} = this.props;

        const {edit} = this.state;

        return (
            <ShortcutWrapper shortcuts={[
                {shortcut: SHORTCUTS_LIST.O, action: this.openEditForm}
            ]}>
                <div>
                    <div className="head-panel">
                        <div className="container">
                            <div className="head-panel__overlay" onClick={this.openEditForm}>
                                <div className="head-panel__overlay__icon">
                                    <Glyphicon glyph="pencil" />
                                    <Shortcut label={null} shortcut={SHORTCUTS_LIST.O} action={this.openEditForm} />
                                </div>
                            </div>

                            {this.renderDestination('pick-up', pickUp, dates?.min)}
                            {this.renderDestination('drop-off', differentDropOff ? dropOff : pickUp, dates?.max)}
                        </div>
                    </div>

                    {edit && (
                        <div className="head-panel__form show">
                            <div className="head-panel__form-wrapper">
                                <OutsideClickHandler onOutsideClick={this.closeEditForm}>
                                    <CarsDestinationSearch editable />
                                </OutsideClickHandler>
                            </div>
                        </div>
                    )}
                </div>
            </ShortcutWrapper>
        );
    }
}
