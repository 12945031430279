import React from 'react';
import PropTypes from 'prop-types';
import {gettext} from 'airborne/gettext';

import AnnotateTooltip from 'airborne/search2/hotel/AnnotateTooltip';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import {formatStrict as formatPrice} from 'airborne/utils/price';
import annotateCurrency from 'airborne/search2/helpers/annotateCurrency';


export default class RequestLabel extends React.Component {
    static propTypes = {
		isOnRequest: PropTypes.bool.isRequired,
    };

    render() {
        const {isOnRequest} = this.props;
        if (!isOnRequest) {
        	return null;
        }

        return (
        	<div style={{marginTop: 15}} className="c-content__item__col">
				<AnnotateTooltip id="on-request">
					<p className="h-rate__options h-rate__options--orange text-xs">
						<strong>
							{gettext('On-request rate')}{' '}
							<Glyphicon bsClass="glyphicons" glyph="circle-info"/>
						</strong>
					</p>
					{gettext('On-request rates confirmation will not be immediate and may take up to 48 hours.')}
				</AnnotateTooltip>
			</div>
		);
    }
}
