import {MapContainer} from 'react-leaflet'; //eslint-disable-line
import React from 'react';
import Map3 from './maploader3';
import Button from 'react-bootstrap/lib/Button';
import gettext from 'airborne/gettext';
import {filterRates} from 'airborne/store/modules/search_cars/actions/rates';
import {connect} from 'react-redux';
import {getFilters} from 'airborne/store/modules/search_cars/selectors';
import {getMapView} from 'airborne/store/modules/search_cars/selectors';
import {toggleMapView} from 'airborne/store/modules/search_cars/actions/viewMap';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {getCarsCoordinates, getUniqList} from 'airborne/search_cars/map/helpers';

const mapStateToProps = (state) => ({
    filters: getFilters(state),
    showMap: getMapView(state),
    isEmptyList: isEmpty(getCarsCoordinates(state)),
    isEmptyUniqList: isEmpty(getUniqList(state)),
});

@connect(mapStateToProps, {filterRates, toggleMapView})
export default class CarsMap extends React.Component {
    static propTypes = {
        filters: PropTypes.object.isRequired,
        showMap: PropTypes.bool.isRequired,
        isEmptyList: PropTypes.bool,
        isEmptyUniqList: PropTypes.bool,
        filterRates: PropTypes.func.isRequired,
        toggleMapView: PropTypes.func.isRequired,
    }

    toggleMap = () => {
        const {filters, filterRates, showMap, toggleMapView} = this.props;
        if (!showMap) {
            filterRates({
                value: {...filters, vendorLocationCode: null}
            });
        }

        toggleMapView();
    }
    render() {
        const {showMap, isEmptyList, isEmptyUniqList} = this.props;
        if (isEmptyList && isEmptyUniqList) return null;

        return (<>
            <MapContainer ref="map" zoomControl={false}>
                <div className="map-trigger-lnk">
                    <Button bsStyle="link" onClick={this.toggleMap} />
                    <span className="map-trigger-lnk-text">{gettext('View on Map')}</span>
                </div>
            </MapContainer>
            {showMap && <Map3 toggleMap={this.toggleMap}  />}
        </>);
    }
};
