import React from 'react';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import CarsSorters from './filters/CarSorting';

import markup from 'airborne/markup';
import {gettext, ngettext} from 'airborne/gettext';
import {isFiltered} from 'airborne/search_cars/helpers/filters';
import {clearFilters} from 'airborne/store/modules/search_cars/actions/rates';
import {getRates, getFilters, getFilteredRates} from 'airborne/store/modules/search_cars/selectors';
import {sortRates} from 'airborne/store/modules/search_cars/actions/rates';


const mapStateToProps = createSelector(
    [getRates, getFilters, getFilteredRates, (state) => state.cars.order],
    (rates, filters, filteredRates, order)=> ({
        total: rates.length,
        filtered: isFiltered(filters),
        filteredTotal: filteredRates.length,
        order,
    })
);


@connect(mapStateToProps, {clearFilters, sortRates})
export default class Bar extends React.Component {
    static propTypes = {
        total: PropTypes.number.isRequired,
        filtered: PropTypes.bool.isRequired,
        filteredTotal: PropTypes.number.isRequired,
        order: PropTypes.string.isRequired,
        clearFilters: PropTypes.func.isRequired,
        sortRates: PropTypes.func.isRequired,
    };

    renderNumber() {
        const {total, filteredTotal} = this.props;
        const text = filteredTotal === total ?
            ngettext('**{total}** rate available', '**{total}** rates available.', total, {total}) :
            ngettext('**{filteredTotal}** of **{total}** rate available', '**{filteredTotal}** of **{total}** rates available.', total, {total, filteredTotal});
        const filterDisclaimer = total === 0 ?
            ' ' + gettext('Your search returned no results, retry with different search criteria.') :
            '';
        return markup(text + filterDisclaimer, 'c-content__total__num');
    }

    renderClearButton() {
        return (
            <Button bsSize="xsmall" bsStyle="link" className="btn-link--narrow" onClick={this.props.clearFilters}>
                &nbsp;&times; {gettext('Clear all filters')}
            </Button>
        );
    }

    render() {
        const {sortRates, order} = this.props;
        return (
            <div className="c-content__bar">
                <div className="c-content__total">
                    {this.renderNumber()}
                    {this.props.filtered && this.renderClearButton()}
                </div>
                <div className="c-content__bar__filter">
                    <CarsSorters value={order} onChange={sortRates}/>
                </div>
            </div>
        );
    }
}
