import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';

import gettext from 'airborne/gettext';
import {toHomepage} from 'airborne/store/modules/search_hotels/actions/navigate';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';

@connect(null, {toHomepage})
export default class HomepageButton extends React.Component {
    static propTypes = {
        toHomepage: PropTypes.func.isRequired,
    };

    renderLabel() {
        return (
            <>« {gettext('Back to Homepage')}</>
        );
    }

    render() {
        return (
            <div className="c-sidebar__trigger-wrapper">
                <Button onClick={this.props.toHomepage} bsStyle="default" className="btn-block btn-default--outline">
                    <Shortcut label={this.renderLabel()} shortcut={SHORTCUTS_LIST.A} action={this.props.toHomepage} isTab />
                </Button>
            </div>
        );
    }
}
